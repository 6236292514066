import { match } from "ts-pattern";
import { getSchema, cleanInputForSubmission } from "./util";
import type {
  CogInputPropertySchema,
  CogInputSchema,
  PlaygroundModel,
} from "./types";

interface SnippetParams {
  model: PlaygroundModel;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  input: Record<string, any>;
  isOfficial: boolean;
}

export function indent(
  value: string,
  indentation: number | { first: number; inner: number; last: number },
): string {
  const lines = value.split("\n");
  return lines
    .map((line, index) => {
      let lineIndentation: number;
      if (typeof indentation === "number") {
        lineIndentation = indentation;
      } else if (index === 0) {
        lineIndentation = indentation.first;
      } else if (index === lines.length - 1) {
        lineIndentation = indentation.last;
      } else {
        lineIndentation = indentation.inner;
      }

      return " ".repeat(lineIndentation) + line;
    })
    .join("\n");
}

function formatFileAsExampleUrl(file: File): string {
  return `https://example.com/${file.name}`;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function formatPythonValue(value: any, schema: CogInputPropertySchema): string {
  return match(schema)
    .with(
      { type: "string", format: "uri" },
      () => value instanceof File,
      () => JSON.stringify(formatFileAsExampleUrl(value as File)),
    )
    .with({ type: "boolean" }, () => (value ? "True" : "False"))
    .otherwise(() => JSON.stringify(value));
}

function formatPythonInput(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true,
): string {
  return [
    "{",
    Object.entries(input)
      .map(
        ([key, value]) => `"${key}": ${formatPythonValue(value, schema[key])}`,
      )
      .join(withLinebreaks ? ",\n" : ", "),
    "}",
  ].join(withLinebreaks ? "\n" : " ");
}

export function makePythonRunSnippet({
  model,
  input,
  isOfficial,
}: SnippetParams) {
  const { input: schema } = getSchema(model);
  const cleanedInput = cleanInputForSubmission(input, schema);
  const formattedInput = formatPythonInput(cleanedInput, schema);

  const identifier = isOfficial
    ? `${model.owner}/${model.name}`
    : `${model.owner}/${model.name}:${model.latest_version.id}`;

  if (isOfficial) {
    return `import replicate
for event in replicate.stream(
    "${identifier}",
    input=${indent(formattedInput, { first: 0, inner: 8, last: 4 })},
):
    print(str(event), end="")
`.trim();
  }

  return `import replicate

output = replicate.run(
  "${identifier}",
  input=${indent(formattedInput, { first: 0, inner: 4, last: 2 })}
)

print(output)`;
}

export function makePythonImportAndConfigureClientSnippet() {
  return "import replicate";
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function formatNodeValue(value: any, schema: CogInputPropertySchema): string {
  return JSON.stringify(
    match(schema)
      .with(
        { type: "string", format: "uri" },
        () => value instanceof File,
        () => formatFileAsExampleUrl(value as File),
      )
      .otherwise(() => value),
  );
}

export function formatNodeInput(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true,
): string {
  return [
    "{",
    Object.entries(input)
      .map(([key, value]) => `${key}: ${formatNodeValue(value, schema[key])}`)
      .join(withLinebreaks ? ",\n" : ", "),
    "}",
  ].join(withLinebreaks ? "\n" : " ");
}

export function makeNodeRunSnippet({
  model,
  input,
  isOfficial,
}: SnippetParams) {
  const { input: schema } = getSchema(model);
  const cleanedInput = cleanInputForSubmission(input, schema);
  const formattedInput = formatNodeInput(cleanedInput, schema);

  const identifier = isOfficial
    ? `${model.owner}/${model.name}`
    : `${model.owner}/${model.name}:${model.latest_version.id}`;

  if (isOfficial) {
    return `import Replicate from "replicate";
const replicate = new Replicate();

const input = ${indent(formattedInput, {
      first: 0,
      inner: 2,
      last: 0,
    })};

for await (const event of replicate.stream("${identifier}", { input })) {
  process.stdout.write(event.toString());
};
    `.trim();
  }

  return `import Replicate from "replicate";
const replicate = new Replicate();

const output = await replicate.run(
  "${identifier}",
  {
    input: ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }
);
console.log(output);`;
}

export function makeNodeImportAndConfigureClientSnippet() {
  return `import Replicate from "replicate";

const replicate = new Replicate({
  auth: process.env.REPLICATE_API_TOKEN,
});`;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function formatHTTPValue(value: any, schema: CogInputPropertySchema): string {
  return (
    JSON.stringify(
      match(schema)
        .with(
          { type: "string", format: "uri" },
          () => value instanceof File,
          () => formatFileAsExampleUrl(value as File),
        )
        .otherwise(() => value),
    )
      // Escape \ in the input into \\.
      .replace(/\\/g, "\\\\")
      // Escape ' in the input into \'. Note that this escaping is because we
      // wrap the input string in single quotes and needs to happen after the
      // escaping of escapes done in the line above.
      .replace(/'/g, "\\'")
  );
}

function formatHTTPInput(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  withLinebreaks = true,
): string {
  return [
    "{",
    Object.entries(input)
      .map(([key, value]) => `"${key}": ${formatHTTPValue(value, schema[key])}`)
      .join(withLinebreaks ? ",\n" : ", "),
    "}",
  ].join(withLinebreaks ? "\n" : " ");
}

export function makeHTTPRunSnippet({
  model,
  input,
  isOfficial,
}: SnippetParams) {
  const { input: schema } = getSchema(model);
  const cleanedInput = cleanInputForSubmission(input, schema);
  const formattedInput = formatHTTPInput(cleanedInput, schema);

  if (isOfficial) {
    return String.raw`curl -s -X POST \
  -H "Authorization: Token $REPLICATE_API_TOKEN" \
  -H "Content-Type: application/json" \
  -d $'{
    "input": ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }' \
  https://api.replicate.com/v1/models/${model.owner}/${model.name}/predictions`;
  }

  return String.raw`curl -s -X POST \
  -H "Authorization: Token $REPLICATE_API_TOKEN" \
  -H "Content-Type: application/json" \
  -d $'{
    "version": "${model.latest_version.id}",
    "input": ${indent(formattedInput, { first: 0, inner: 6, last: 4 })}
  }' \
  https://api.replicate.com/v1/predictions`;
}
