import { useCopyToClipboard } from "@/hooks";
import { CodeCTAs } from "./code-ctas";
import { InteractiveCodeSnippet } from "./interactive-code-snippet";
import { makeNodeRunSnippet } from "@/code-snippets";
import { getModelDisplayInput } from "@/util";
import { isOfficialModel } from "@/data";
import type { PlaygroundModel } from "@/types";

export function JSCodeForm({
  name,
  model,
  prompt,
}: {
  // name of the field
  name: string;
  model: PlaygroundModel;
  prompt: string;
}) {
  const [copiedValue, copy] = useCopyToClipboard();

  const isOfficial = isOfficialModel(`${model.owner}/${model.name}`);

  const code = makeNodeRunSnippet({
    model,
    input: getModelDisplayInput({ model, name, prompt }),
    isOfficial,
  });

  const handleCopy = () => {
    copy(code);
  };

  return (
    <div className="group">
      <CodeCTAs onCopy={handleCopy} copiedValue={copiedValue} />
      <InteractiveCodeSnippet
        language="javascript"
        markers={[
          {
            line: 1,
            className: "opacity-50 group-hover:opacity-100 transition-opacity",
          },
          {
            line: 2,
            className: "opacity-50 group-hover:opacity-100 transition-opacity",
          },
        ]}
        code={code}
      />
    </div>
  );
}
